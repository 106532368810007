import { useState } from 'react'
import axios from 'axios'
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { Button, TextField, InputAdornment, CircularProgress, Box } from '@mui/material';
import { Send as SendIcon, Search as SearchIcon, RestartAlt as RestartAltIcon } from '@mui/icons-material';

import { Table } from '../component'
import { styled } from '@mui/system';

const HomeWrapper = styled('div')({
    width: '100%',
})

const HomeTitle = styled('h1')({
    color: '#282c34',
    marginTop: 0,
})

const HomeSearchBar = styled('form')({
    marginBottom: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

const HomeTextAlert = styled('h1')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2.3rem',
    marginTop: '25vh',
    [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
    },
}))

const Home = () => {
    const [rows, setRows] = useState([])
    const { acquireToken: acquireTokenSearch } = useMsalAuthentication(InteractionType.Popup, {
        scopes: ["https://search.azure.com/user_impersonation",]
    });

    const getData = async (e) => {
        e.preventDefault();
        if (!search)
            return;
        try {
            setLoading(true);
            const { accessToken } = await acquireTokenSearch();
            const headers = {
                'api-key': process.env.REACT_APP_API_KEY,
                Authorization: `Bearer ${accessToken}`
            };
            const body = {
                'search': search
            };

            const responseAR = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/indexes/${process.env.REACT_APP_INDEX_NAME_AR}/docs/search?api-version=${process.env.REACT_APP_API_VERSION}`,
                body, {
                headers: headers,
            });
            const responseNT = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/indexes/${process.env.REACT_APP_INDEX_NAME_NT}/docs/search?api-version=${process.env.REACT_APP_API_VERSION}`,
                body, {
                headers: headers,
            });

            const newRowsAR = Object.values(responseAR.data.value).map((item) => ({
                //searchScore: item['@search.score'],
                id: item['content']['Location'],
                location: item['content']['CrossIndustryInvoice']['ExchangedDocument']['ID'],
                name: item['content']['CrossIndustryInvoice']['ExchangedDocument']['Name'],
                typeCode: item['content']['CrossIndustryInvoice']['ExchangedDocument']['TypeCode'],
                vendorName: item['content']['CrossIndustryInvoice']['SupplyChainTradeTransaction']['ApplicableHeaderTradeAgreement']['BuyerTradeParty']['Name'],
                creationDateTime: item['content']['CrossIndustryInvoice']['ExchangedDocument']['CreationDateTime'],
            }))

            const newRowsNT = Object.values(responseNT.data.value).map((item) => ({
                //searchScore: item['@search.score'],
                id: item['content']['Location'],
                location: item['content']['CrossIndustryInvoice']['ExchangedDocument']['ID'],
                name: item['content']['CrossIndustryInvoice']['ExchangedDocument']['Name'],
                typeCode: item['content']['CrossIndustryInvoice']['ExchangedDocument']['TypeCode'],
                vendorName: item['content']['CrossIndustryInvoice']['SupplyChainTradeTransaction']['ApplicableHeaderTradeAgreement']['BuyerTradeParty']['Name'],
                creationDateTime: item['content']['CrossIndustryInvoice']['ExchangedDocument']['CreationDateTime'],
            }))

            const newRows = [...newRowsAR, ...newRowsNT].map((item, index) => (

                {
                    ...item,
                    runNumber: index + 1,
                }));
            setRows(newRows);

        } catch (error) {
            console.error('can\'t request data from api.', error);
        } finally {
            setLoading(false);
        }
    }

    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)

    let dataElement = <HomeTextAlert>ค้นหาชื่อเอกสาร</HomeTextAlert>;
    if (loading && !rows.length) dataElement =
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '25vh', }}>
            <CircularProgress />
        </Box>
    if (loading && rows.length) dataElement =
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '25vh', }}>
            <CircularProgress />
        </Box>
    if (!loading && rows.length) dataElement =
        <Table rows={rows} />

    return (
        <HomeWrapper>
            <HomeTitle>ตาราง</HomeTitle>
            <HomeSearchBar onSubmit={getData}>
                <TextField InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                    id='outlined-search' label='ช่องค้นหา' type='search' required placeholder='ค้นหาชื่อเอกสาร...' onChange={(e) => setSearch(e.target.value)} value={search} />
                <Button variant='contained' type='submit' size='large' endIcon={<SendIcon />} sx={{ marginLeft: '1rem', }}>ส่ง</Button>
                {rows.length > 0 && (
                    <Button variant='outlined' size='large' color='error' startIcon={<RestartAltIcon />} sx={{ marginLeft: '1rem', }} onClick={() => { setRows([]); setSearch('') }}>คืนค่าตาราง</Button>
                )}
            </HomeSearchBar>
            {dataElement}
        </HomeWrapper>
    )
}

export default Home