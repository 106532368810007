import React, { useState } from 'react'
import { styled } from '@mui/system';
import { useMsal } from "@azure/msal-react";
import DescriptionIcon from '@mui/icons-material/Description';
import PersonIcon from '@mui/icons-material/Person';

const Banner = () => {

    const { instance, accounts } = useMsal();
    const [dropdown, setDropdown] = useState(false);

    return (
        <BannerWrapper>
            <BannerItem>
                <BannerLogo />
                <BannerTitleSKC>SKC</BannerTitleSKC>
                <BannerTitle>INVOICE-DOCUMENT</BannerTitle>
            </BannerItem>
            <BannerItem>
                <BannerUserItem onClick={() => { setDropdown(!dropdown) }}>
                    <BannerUserIcon />
                    <BannerUserName>{accounts[0]?.name}</BannerUserName>
                </BannerUserItem>
                {dropdown &&
                    <BannerDropdownLogout onClick={() => { instance.logoutRedirect() }}>Logout</BannerDropdownLogout>
                }
            </BannerItem>
        </BannerWrapper>
    )
}

export default Banner

const BannerWrapper = styled('div')({
    width: '100%',
    height: '4rem',
    backgroundColor: '#E4E8EE',
    display: 'flex',
    justifyContent: 'space-between',
});

const BannerItem = styled('div')({
    paddingInline: '1rem',
    display: 'flex',
    alignItems: 'center',
});

const BannerLogo = styled(DescriptionIcon)({
    color: '#009b9f',
    fontSize: '2rem',
});

const BannerTitleSKC = styled('h1')(({ theme }) => ({
    marginLeft: '1rem',
    color: '#009b9f',
    fontSize: '1.5rem',
    [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
    },
}));

const BannerTitle = styled('h1')(({ theme }) => ({
    marginLeft: '1rem',
    color: '#282c34',
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
    }
}));

const BannerUserItem = styled('div')({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
})

const BannerUserIcon = styled(PersonIcon)({
    color: '#009b9f',
    fontSize: '2rem',
    justifyContent: 'flex-end',
});

const BannerUserName = styled('p')(({ theme }) => ({
    paddingLeft: '0.5rem',
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'block',
    },
}))

const BannerDropdownLogout = styled('div')({
    position: 'absolute',
    top: '55px',
    right: '2%',
    padding: '0.5rem',
    width: '200px',
    backgroundColor: '#FFFFFF',
    border: '1px',
    borderColor: '#D7D7D7',
    borderStyle: 'solid',
    borderRadius: '5px',
    cursor: 'pointer',
})