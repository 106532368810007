import React from 'react';
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { Banner } from './component';
import { Home, Login } from './pages';
import { styled } from '@mui/system';

const THEME = createTheme({
  typography: {
    "fontFamily": `"Sarabun", sans-serif`,
  }
});

const PageWrapper = styled('div')({
  width: 'screen',
  height: '100%',
  padding: '2rem'
})

export default function App() {
  return (
    <>
      <MsalAuthenticationTemplate>
        <ThemeProvider theme={THEME}>
          <CssBaseline />
          <Banner />
          <PageWrapper>
            <Home />
          </PageWrapper>
        </ThemeProvider>
      </MsalAuthenticationTemplate>

      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
}