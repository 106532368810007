import * as React from 'react';
import Box from '@mui/material/Box';
import axios from 'axios'
import { useMsalAuthentication } from '@azure/msal-react';
import SaveIcon from '@mui/icons-material/Save';
import { format } from "date-fns";
import { InteractionType } from '@azure/msal-browser';
import {
  DataGrid,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';

const Table = ({ rows }) => {
  const { acquireToken: acquireTokenStorage } = useMsalAuthentication(InteractionType.Popup, {
    scopes: ["https://storage.azure.com/user_impersonation"]
  });

  const handleLocationClick = (id) => async () => {

    let idArray = id.split('\\')
    id = idArray.join('/')
    if (id.includes('AR_Non_Trade')) {
      idArray[6] = '00'
      id = idArray.join('/')
    }

    try {
      const { accessToken: accessTokenStorage } = await acquireTokenStorage()
      const URL = `https://d701sasi07kbtcom.blob.core.windows.net${id.replace('Json', 'PDF')}.pdf`
      const blobResponse = await axios.get(URL, {
        headers: {
          'Authorization': `Bearer ${accessTokenStorage}`,
          'x-ms-date': new Date().toUTCString(),
          'x-ms-version': '2022-11-02'
        },
        responseType: 'blob'
      })
      const blobURL = window.URL.createObjectURL(blobResponse.data);
      const filename = URL.slice(
        URL.lastIndexOf("/") + 1,
        URL.lastIndexOf(".")
      );

      const link = document.createElement('a');
      link.href = blobURL;
      link.setAttribute('download', filename);

      link.click();

    } catch (error) {
      console.error("Error in handleLocationClick:", error);
      window.alert('ไม่สามารถเรียกดูไฟล์ได้ ติดต่อผู้ดูแลระบบ')
    }
  }

  const columns = [
    { field: 'runNumber', headerClassName: 'column-header', headerName: 'No.', width: 75, headerAlign: 'center', align: 'center' },
    { field: 'typeCode', headerClassName: 'column-header', headerName: 'Typecode', width: 120, headerAlign: 'center', align: 'center' },
    { field: 'location', headerClassName: 'column-header', headerName: 'Document No.', width: 150, headerAlign: 'center', align: 'center' },
    { field: 'vendorName', headerClassName: 'column-header', headerName: 'Vendor Name', width: 500, headerAlign: 'center', align: 'center' },
    // { field: 'searchScore', headerClassName: 'column-header', headerName: 'Search score', type: 'number', width: 140, headerAlign: 'center', align: 'center' },
    {
      field: 'creationDateTime', headerClassName: 'column-header', headerName: 'Creation date', width: 145, headerAlign: 'center', align: 'center',
      valueGetter: (params) => { return format(new Date(params.row.creationDateTime), 'yyyy/MM/dd')},
    },

    {
      field: 'id',
      type: 'actions',
      headerName: 'Download',
      headerClassName: 'column-header',
      width: 100,
      sortable: false,
      getActions: ({ id }) => {
        return [
          <Tooltip title={id}>
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleLocationClick(id)}
            />
          </Tooltip>
        ];
      }
    },

  ];

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '.column-header': {
          fontSize: '16px',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          sorting: {
            sortModel: [{ field: 'searchScore', sort: 'desc' }],
          },
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
      />
    </Box>
  );
}

export default Table